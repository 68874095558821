import API_URL from './apiUrl';

class PolygonService {

  addPolygonToProject(projectId, data) {
    return API_URL.post(`/survey/projects/${projectId}/polygons`, data
      ,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  getPolygonsByProjectId(projectId) {
    return API_URL.get(`/survey/projects/polygon/${projectId}`);
  }

  getPolygons() {
    return API_URL.get(`/survey/projects/polygons`);
  }

  deletePolygon(polygonId) {
    return API_URL.delete(`/survey/projects/polygons/delete/${polygonId}`);
  }

  // addPolygonToProject(projectId, data) {
  //   return API_URL.post(`/survey/projects/${projectId}/polygons`, data, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });
  // }

}

export default new PolygonService();