import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendHolidayRequest } from "../../actions/HolidaysActions";
import { getAllUsers } from "../../actions/UserActions";
import {
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";

const SendHolidayRequest = ({ currentUser }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [formData, setFormData] = useState({
    iduser: "",
    requestDays: "",
    startDate: "",
    endDate: "",
    cause: "",
    isPaid: "",
  });
  const [snackbarData, setSnackbarData] = useState(null);
  const [calculatedTotalDays, setCalculatedTotalDays] = useState(0);

  const { iduser, requestDays, startDate, isPaid, cause } = formData;

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  let userId = '';
  if (currentUser.roles.includes("ROLE_ADMIN")) {
    userId = iduser;
  } else {
    userId = currentUser.id;
  }

  const fixedHolidays = [
    "01-01", "11-01", "14-01", "05-01", "07-30", "08-14", "08-20", "08-21", "11-06", "11-18"
  ];

  const isHoliday = (date) => {
    const dayMonth = date.toISOString().slice(5, 10);
    return fixedHolidays.includes(dayMonth);
  };

  const calculateEndDate = () => {
    if (!requestDays || !startDate) return { endDate: "", totalDays: 0 };

    const start = new Date(startDate);
    let remainingDays = parseInt(requestDays, 10);
    let totalDays = 0;
    let end = new Date(start);

    while (remainingDays > 0) {
      end.setDate(end.getDate() + 1);
      totalDays += 1;
      const day = end.getDay();
      if (day !== 0 && !isHoliday(end)) {
        remainingDays -= 1;
      }
    }
    return { endDate: end.toISOString().split('T')[0], totalDays };
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (requestDays && startDate) {
      const { endDate, totalDays } = calculateEndDate();
      setFormData({ ...formData, endDate });
      setCalculatedTotalDays(totalDays);
    }
  }, [requestDays, startDate]);

  let status = '';
  if (currentUser.roles.includes("ROLE_ADMIN")) {
    status = 'confirmed';
  } else {
    status = 'pending';
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { endDate } = calculateEndDate();

    const holidayRequest = {
      userId: userId,
      requestDays: parseInt(requestDays),
      startDate,
      endDate,
      cause,
      isPaid,
      status: status,
    };

    dispatch(sendHolidayRequest(holidayRequest))
      .then(() => {
        setSnackbarData({ type: "success", message: "Demande de congé envoyée avec succès." });
      })
      .catch((error) => {
        setSnackbarData({ type: "error", message: "Failed to send the holiday request." });
        console.error("Failed to send the holiday request:", error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarData(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
        || currentUser.permissions.includes("Envoyer des demandes de congés")
        || currentUser.permissions.includes("Ajouter des congés")) ? (
        <Container maxWidth="md">
          <Typography variant="h2" mb={2} textAlign={'center'}>
            {(currentUser.roles.includes("ROLE_ADMIN")
              || currentUser.permissions.includes("Ajouter des congés")) ? (
              <>
                Ajouter un congé
              </>
            ) : (
              <>
                Envoyer une demande de congé
              </>
            )}
          </Typography>
          <form className="p-2" onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des congés")) &&
                <Grid item xs={12}>
                  <TextField
                    select
                    label='Sélectionnez Personnel'
                    name="iduser"
                    value={iduser}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    variant="standard"
                  >
                    <MenuItem value="" disabled>
                      Sélectionnez ...
                    </MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user?.nom}&nbsp;{user?.prenom}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  type="number"
                  name="requestDays"
                  value={requestDays}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  variant="standard"
                  label="Jours de demande"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  variant="standard"
                  label="Date de début"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  disabled
                  fullWidth
                  variant="standard"
                  label="Date de fin"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  name="calculatedTotalDays"
                  value={calculatedTotalDays}
                  disabled
                  fullWidth
                  variant="standard"
                  label="Jours calculés"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="cause"
                  value={cause}
                  onChange={handleInputChange}
                  fullWidth
                  variant="standard"
                  label="Cause (facultatif)"
                />
              </Grid>
              {(currentUser.roles.includes("ROLE_ADMIN")) && (
                <Grid item xs={12}>
                  <TextField
                    select
                    label='Payé'
                    name="isPaid"
                    value={isPaid}
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="oui">Oui</MenuItem>
                    <MenuItem value="non">Non</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <MDButton
                  type="submit"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des congés")) ? (
                    <>
                      Ajouter
                    </>
                  ) : (
                    <>
                      Envoyer la demande
                    </>
                  )}

                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={snackbarData !== null}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarData?.type || "success"}
              variant="filled"
            >
              {snackbarData?.message}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default SendHolidayRequest;


// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { sendHolidayRequest } from "../../actions/HolidaysActions";
// import { getAllUsers } from "../../actions/UserActions";
// import {
//   Container,
//   Grid,
//   MenuItem,
//   TextField,
//   Typography,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import MDButton from "../../components/MDButton";
// import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
// import AccessDenied from "../Errors/AccessDenied";

// const SendHolidayRequest = ({ currentUser }) => {
//   const dispatch = useDispatch();
//   const users = useSelector((state) => state.users.users);
//   const [formData, setFormData] = useState({
//     iduser: "",
//     requestDays: "",
//     startDate: "",
//     endDate: "",
//     cause: "",
//     isPaid: "",
//   });
//   const [snackbarData, setSnackbarData] = useState(null);

//   const { iduser, requestDays, startDate, isPaid, endDate, cause } = formData;

//   useEffect(() => {
//     dispatch(getAllUsers());
//   }, [dispatch]);

//   let userId = '';
//   if (currentUser.roles.includes("ROLE_ADMIN")) {
//     userId = iduser;
//   } else {
//     userId = currentUser.id;
//   }

//   const calculateEndDate = () => {
//     if (requestDays && startDate) {
//       const start = new Date(startDate);
//       const end = new Date(start);
//       end.setDate(end.getDate() + parseInt(requestDays));
//       return end.toISOString().split('T')[0];
//     }
//     return "";
//   };

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   let status = '';
//   if (currentUser.roles.includes("ROLE_ADMIN")) {
//     status = 'confirmed';
//   } else {
//     status = 'pending';
//   }
//   const handleFormSubmit = (e) => {
//     e.preventDefault();

//     const holidayRequest = {
//       userId: userId,
//       requestDays: parseInt(requestDays),
//       startDate,
//       endDate: calculateEndDate(),
//       cause,
//       isPaid,
//       status: status,
//     };

//     dispatch(sendHolidayRequest(holidayRequest))
//       .then(() => {
//         setSnackbarData({ type: "success", message: "Demande de congé envoyée avec succès." });
//       })
//       .catch((error) => {
//         setSnackbarData({ type: "error", message: "Failed to send the holiday request." });
//         console.error("Failed to send the holiday request:", error);
//       });
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarData(null);
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
//         || currentUser.permissions.includes("Envoyer des demandes de congés")
//         || currentUser.permissions.includes("Ajouter des congés")) ? (
//         <Container maxWidth="md">
//           <Typography variant="h2" mb={2} textAlign={'center'}>
//             {(currentUser.roles.includes("ROLE_ADMIN")
//               || currentUser.permissions.includes("Ajouter des congés")) ? (
//               <>
//                 Ajouter un congé
//               </>
//             ) : (
//               <>
//                 Envoyer une demande de congé
//               </>
//             )}
//           </Typography>
//           <form className="p-2" onSubmit={handleFormSubmit}>
//             <Grid container spacing={2}>
//               {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des congés")) &&
//                 <Grid item xs={12}>
//                   <TextField
//                     select
//                     label='Sélectionnez Personnel'
//                     name="iduser"
//                     value={iduser}
//                     onChange={handleInputChange}
//                     required
//                     fullWidth
//                     variant="standard"
//                   >
//                     <MenuItem value="" disabled>
//                       Sélectionnez ...
//                     </MenuItem>
//                     {users.map((user) => (
//                       <MenuItem key={user.id} value={user.id}>
//                         {user?.nom}&nbsp;{user?.prenom}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Grid>
//               }
//               <Grid item xs={12}>
//                 <TextField
//                   type="number"
//                   name="requestDays"
//                   value={requestDays}
//                   onChange={handleInputChange}
//                   required
//                   fullWidth
//                   variant="standard"
//                   label="Jours de demande"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   type="date"
//                   name="startDate"
//                   value={startDate}
//                   onChange={handleInputChange}
//                   required
//                   fullWidth
//                   variant="standard"
//                   label="Date de début"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   type="date"
//                   name="endDate"
//                   value={calculateEndDate()}
//                   disabled
//                   fullWidth
//                   variant="standard"
//                   label="Date de fin"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   name="cause"
//                   value={cause}
//                   onChange={handleInputChange}
//                   fullWidth
//                   variant="standard"
//                   label="Cause (facultatif)"
//                 />
//               </Grid>
//               {(currentUser.roles.includes("ROLE_ADMIN")) && (
//                 <Grid item xs={12}>
//                   <TextField
//                     select
//                     label='Payé'
//                     name="isPaid"
//                     value={isPaid}
//                     fullWidth
//                     variant="standard"
//                     onChange={handleInputChange}
//                   >
//                     <MenuItem value="oui">Oui</MenuItem>
//                     <MenuItem value="non">Non</MenuItem>
//                   </TextField>
//                 </Grid>
//               )}
//               <Grid item xs={12}>
//                 <MDButton
//                   type="submit"
//                   variant="contained"
//                   color="info"
//                   fullWidth
//                 >
//                   {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des congés")) ? (
//                     <>
//                       Ajouter
//                     </>
//                   ) : (
//                     <>
//                       Envoyer la demande
//                     </>
//                   )}

//                 </MDButton>
//               </Grid>
//             </Grid>
//           </form>
//           <Snackbar
//             open={snackbarData !== null}
//             autoHideDuration={6000}
//             onClose={handleSnackbarClose}
//             anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//           >
//             <Alert
//               onClose={handleSnackbarClose}
//               severity={snackbarData?.type || "success"}
//               variant="filled"
//             >
//               {snackbarData?.message}
//             </Alert>
//           </Snackbar>
//         </Container>
//       ) : (
//         <>
//           <AccessDenied />
//         </>
//       )}
//     </DashboardLayout>
//   );
// };

// export default SendHolidayRequest;
