import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { register } from '../../actions/UserActions';
import {
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Grid,
  Snackbar,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';

const AddUser = ({ currentUser }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    username: '',
    cin: '',
    nom: '',
    prenom: '',
    email: '',
    password: '',
    adresse: '',
    date_recrutement: '',
    salaire: 0,
    telephone: '',
    contrat: '',
    situation_fam: '',
    nb_enf: 0,
    decraltion_cnss: '',
    ncnss: '',
    rib: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(register(userData))
      .then(() => {
        setSnackbarType('success');
        setSnackbarMessage('User registered successfully');
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarType('error');
        setSnackbarMessage(error?.message);
        setOpenSnackbar(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(currentUser && ((currentUser.roles.includes("ROLE_ADMIN")) || (currentUser.permissions.includes("Ajouter des personnel")))) ? (
        <div>
          <Typography variant="h3" mb={3} className="text-center">
            Ajouter un nouvel utilisateur
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Username"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="CIN"
                  name="cin"
                  value={userData.cin}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nom"
                  name="nom"
                  value={userData.nom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Prenom"
                  name="prenom"
                  value={userData.prenom}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={userData.password}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Adresse"
                  name="adresse"
                  value={userData.adresse}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date Recrutement"
                  name="date_recrutement"
                  type="date"
                  value={userData.date_recrutement}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Salaire de déclaration"
                  name="salaire"
                  type="number"
                  value={userData.salaire}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Telephone"
                  name="telephone"
                  value={userData.telephone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="RIB"
                  name="rib"
                  value={userData.rib}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                  <InputLabel id="situation_fam-label">Situation familiale</InputLabel>
                  <Select
                    labelId="situation_fam-label"
                    name="situation_fam"
                    value={userData.situation_fam}
                    onChange={handleChange}
                  >
                    <MenuItem value="celibataire">Célibataire</MenuItem>
                    <MenuItem value="marie">Marié(e)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {userData?.situation_fam === 'marie' &&
                <Grid item xs={6}>
                  <TextField
                    label="Nombre d'enfants"
                    name="nb_enf"
                    type='number'
                    value={userData.nb_enf}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              }
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                  <InputLabel id="decraltion_cnss-label">Declaration cnss</InputLabel>
                  <Select
                    labelId="decraltion_cnss-label"
                    name="decraltion_cnss"
                    value={userData.decraltion_cnss}
                    onChange={handleChange}
                  >
                    <MenuItem value="non">Non</MenuItem>
                    <MenuItem value="oui">Oui</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {userData?.decraltion_cnss === 'oui' &&
                <Grid item xs={6}>
                  <TextField
                    label="Numéro du CNSS"
                    name="ncnss"
                    value={userData.ncnss}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              }
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                  <InputLabel id="contrat-label">Contrat</InputLabel>
                  <Select
                    labelId="contrat-label"
                    name="contrat"
                    value={userData.contrat}
                    onChange={handleChange}
                  >
                    <MenuItem value="CDI">CDI</MenuItem>
                    <MenuItem value="CDD">CDD</MenuItem>
                    <MenuItem value="Contrat Projet">Contrat Projet</MenuItem>
                    <MenuItem value="Contrat Stage">Contrat Stage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <MDButton type="submit" variant="contained" color="info">
                  Ajouter
                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ContentProps={{
              sx: {
                backgroundColor: snackbarType === 'success' ? 'green' : 'red',
              },
            }}
          />
        </div>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default AddUser;
