import React, { useEffect, useState, useRef } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProjects, deleteProject } from '../../actions/ProjetActions';
import { Link } from 'react-router-dom';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
    TextField,
    Box, Typography, Modal, Button, IconButton, useMediaQuery, Stack, MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccessDenied from "../Errors/AccessDenied";
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';
import ProjectsReview from './ProjectsReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PolylineIndex from '../geometry/PolylineIndex';
import { encodeId } from '../../actions/Crypte';
import FactureProjet from './FactureProjet';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import { Place } from '@mui/icons-material';
import { MaterialReactTable, useMaterialReactTable, TableFooter, MRT_ActionMenuItem } from 'material-react-table';

const ProjectList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.project.projects || []);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [currentPageTotalPrice, setCurrentPageTotalPrice] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isPolygonProjectOpen, setIsPolygonProjectOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllProjects());
    }, [dispatch]);


    useEffect(() => {
        const filteredProjects = projects?.filter((project) => {
            const reference = project.reference_fonciere || '';
            const numero = project.numero || '';
            const client = project.client?.full_name || '';
            const commercial = project.projectContact?.name || '';
            const creation = project.date_creation || '';
            const situation = project?.situation || '';
            return (
                reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.toLowerCase().includes(searchTerm.toLowerCase()) ||
                commercial.toLowerCase().includes(searchTerm.toLowerCase()) ||
                creation.toLowerCase().includes(searchTerm.toLowerCase()) ||
                situation.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.date_creation === '0000-00-00' && b.date_creation !== '0000-00-00') {
                    return 1;
                } else if (b.date_creation === '0000-00-00' && a.date_creation !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.date_creation) - new Date(a.date_creation);
                }
            });

        setFilteredProjects(filteredProjects);
    }, [projects, searchTerm]);

    useEffect(() => {
        const totalPrice = filteredProjects.reduce((acc, project) => acc + (parseFloat(project.prix) || 0), 0);
        setCurrentPageTotalPrice(totalPrice);
    }, [filteredProjects]);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentProjectId, setCurrentProjectId] = useState(null);

    const openDeleteModal = (projectId) => {
        setDeleteModalOpen(true);
        setCurrentProjectId(projectId);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleDelete = (projectId) => {
        dispatch(deleteProject(projectId));
    };

    const handleConfirmDelete = () => {
        if (currentProjectId) {
            handleDelete(currentProjectId);
            closeDeleteModal();
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const openDialogLocation = (item) => {
        setSelectedItem(item);
        setIsPolygonProjectOpen(true);
    };

    const closeDialogLocation = () => {
        setSelectedItem(null);
        setIsPolygonProjectOpen(false);
    };

    const getStatusColor = (statut) => {
        switch (statut) {
            case 'preparation':
                return '#ffa36d';
            case 'terrain':
                return '#FF8C00';
            case 'bureau':
                return '#FFFF00';
            case 'controle':
                return '#00CED1';
            case 'livraison':
                return '#00FFFF';
            case 'cloture':
                return '#5F9EA0';
            default:
                return '';
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const isUserIncludePrix = currentUser &&
        (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets"));
    const isUserPaiement = currentUser &&
        (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les paiements des projets"));

    const openDialog = (project) => {
        setSelectedItem(project);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const openFactureDialog = (project) => {
        setSelectedItem(project);
        setIsFactureDialogOpen(true);
    };

    const closeFactureDialog = () => {
        setSelectedItem(null);
        setIsFactureDialogOpen(false);
    };

    const maxAge = useMemo(
        () => filteredProjects.reduce((acc, curr) => Math.max(acc, curr.prix), 0),
        [],
    );
    // console.log('currentPageTotalPrice', currentPageTotalPrice);
    const columns = [
        {
            accessorKey: 'place',
            header: ' ',
            size: 40,
            Cell: ({ cell }) => (
                <Button size='large' 
                onClick={() => openDialogLocation(cell.row.original)}
                disabled={(cell.row.original?.polylines?.length === 0)&& (cell.row.original?.polygons?.length === 0)}
                >
                    <Place fontSize='large'/>
                </Button>
            ),
        },
        {
            accessorKey: 'numero',
            header: 'Project',
            Cell: ({ cell }) => (
                <Typography component={Link} to={`/projects/project/${encodeId(cell.row.original.id)}`} className="text-decoration-none text-dark active">
                    {cell.getValue()}
                </Typography>
            ),
        },
        {
            accessorKey: 'client.full_name',
            header: 'Client',
            Cell: ({ cell }) => cell.row.original.client?.full_name || '',
        },
        {
            accessorKey: 'reference_fonciere',
            header: 'Référence Foncière',
        },
        {
            accessorKey: 'nature',
            header: 'Nature',
            Cell: ({ cell }) => cell.row.original.nature.map((natures) => natures.name).join(", "),
        },
        {
            accessorKey: 'date_creation',
            header: 'Créé à',
        },
        {
            accessorKey: 'statut',
            header: 'Status',
            Cell: ({ cell }) => (
                <span className="label label-default" style={{ backgroundColor: getStatusColor(cell.getValue()) }}>
                    {cell.getValue()}
                </span>
            ),
        },
        {
            accessorKey: 'prix',
            header: 'Prix',
            Cell: ({ cell }) => isUserIncludePrix ? cell.getValue() : '',
            Footer: ({ table }) => {
                console.log(table?.rows);
                if (!table || !table.rows) {
                    return null; // Return null if table or table.rows is undefined
                }
                const totalPrice = table.rows.reduce((acc, row) => acc + parseFloat(row.values.prix || 0), 0);
                return (
                    <div>Team Score: {totalPrice.toFixed(2)}</div>
                );
            }
        },

        {
            accessorKey: 'paiement',
            header: 'Paiement',
            Cell: ({ cell }) => isUserPaiement ? cell.getValue() : '',
        },
        // {
        //     accessorKey: 'actions',
        //     header: 'Actions',
        //     Cell: ({ cell }) => (
        //         <>
        //             <Tooltip title="Détails" arrow>
        //                 <IconButton
        //                     color="warning"
        //                     aria-label="Preview"
        //                     onClick={() => openDialog(cell.row.original)}
        //                 >
        //                     <OpenInFullIcon />
        //                 </IconButton>
        //             </Tooltip>
        //             {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des factures")) &&
        //                 <>
        //                     {(cell.row.original?.factures?.length === 0) &&
        //                         <Tooltip title="Facturé le projet" arrow>
        //                             <IconButton
        //                                 color="warning"
        //                                 aria-label="Facturé le projet"
        //                                 onClick={() => openFactureDialog(cell.row.original)}
        //                             >
        //                                 <DescriptionIcon />
        //                             </IconButton>
        //                         </Tooltip>
        //                     }
        //                 </>
        //             }
        //             <Tooltip title="Localisation" arrow>
        //                 <IconButton
        //                     color="warning"
        //                     aria-label="Preview"
        //                     onClick={() => openDialogLocation(cell.row.original)}
        //                 >
        //                     <VisibilityIcon />
        //                 </IconButton>
        //             </Tooltip>
        //             {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier des projets")) &&
        //                 <Tooltip title="Modifier" arrow>
        //                     <IconButton
        //                         component={Link}
        //                         to={`/projects/edit-project/${encodeId(cell.row.original.id)}`}
        //                         color="success"
        //                         aria-label="Modifier"
        //                     >
        //                         <BorderColorIcon />
        //                     </IconButton>
        //                 </Tooltip>
        //             }
        //             {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer des projets")) &&
        //                 <Tooltip title="Supprimer" arrow>
        //                     <IconButton
        //                         color="error"
        //                         aria-label="Supprimer"
        //                         onClick={() => openDeleteModal(cell.row.original.id)}
        //                     >
        //                         <DeleteIcon />
        //                     </IconButton>
        //                 </Tooltip>
        //             }
        //         </>
        //     ),
        // },
    ];


    const AddButton = (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="3.25rem"
                    height="3.25rem"
                    shadow="sm"
                    borderRadius="50%"
                    position="fixed"
                    right="2rem"
                    bottom="2rem"
                    zIndex={99}
                    color="dark"
                    sx={{
                        cursor: "pointer",
                        background: "#0288d1",
                        color: "#fff !important"
                    }}
                    component={Link}
                    to={`/projects/create`}
                >
                    <AddIcon fontSize="small" color="inherit" />
                </Box>
            )}
        </>
    );


    const table = useMaterialReactTable({
        columns,
        data: filteredProjects,
        initialState: {
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [{ id: 'state', desc: false }],
            columnPinning: {
                right: ['mrt-row-actions'],
              },
        },
        rowCount: filteredProjects?.length,
        enableRowSelection: false,
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        enableGrouping: true,
        enableRowActions: true,
        enableColumnPinning: true,
        enableColumnOrdering: true,
        renderRowActionMenuItems: ({ row, table }) => [
            <MRT_ActionMenuItem
                icon={<OpenInFullIcon />}
                key="Preview"
                label="Preview"
                onClick={() => openDialog(row.original)}
                table={table}
            />,
            ((currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des factures")) &&
                <MRT_ActionMenuItem
                    icon={<DescriptionIcon />}
                    key="facture"
                    label="Facturé le projet"
                    onClick={() => openFactureDialog(row.original)}
                    table={table}
                />
            ),
            <MRT_ActionMenuItem
                icon={<VisibilityIcon />}
                key="place"
                label="Localisation"
                onClick={() => openDialogLocation(row.original)}
                table={table}
            />,
            ((currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer des projets")) &&
                <MRT_ActionMenuItem
                    icon={<DeleteIcon />}
                    key="delete"
                    label="Supprimer"
                    onClick={() => openDeleteModal(row.original.id)}
                    table={table}
                />
            ),
        ]
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
                <div className="mb-3">
                    <div className="search-container">
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Rechercher un projet par référence, client, contact, situation, date de création ou numéro"
                            onChange={handleSearch}
                            value={searchTerm}
                            name="Search Project"
                            sx={{ gridColumn: "span 2", marginBottom: '1rem' }}
                        />
                    </div>
                    <Box height="100vh" >
                        <MaterialReactTable
                            table={table}
                            footer={(rows) => {
                                const totalPrice = rows.reduce((acc, row) => acc + parseFloat(row.values.prix || 0), 0);
                                return (
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                        <Typography variant="body1">Total: {totalPrice.toFixed(2)}</Typography>
                                    </Box>
                                );
                            }}
                        />
                    </Box>
                    {AddButton}
                </div>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Modal
                open={isDeleteModalOpen}
                onClose={closeDeleteModal}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box sx={{ ...modalStyle }}>
                    <Typography id="delete-modal-title" variant="h5" component="h2" gutterBottom>
                        Confirmation de suppression
                    </Typography>
                    <Typography id="delete-modal-description" sx={{ marginBottom: '1rem' }}>
                        Êtes-vous sûr de vouloir supprimer ce projet ?
                    </Typography>
                    <MDButton onClick={handleConfirmDelete} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
                        Confirmer la suppression
                    </MDButton>
                    <MDButton color='secondary' onClick={closeDeleteModal} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="100%"
            // maxWidth="lg"
            >
                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                    Détails du projet: {selectedItem?.numero}
                </DialogTitle>
                <DialogContent>
                    <ProjectsReview project={selectedItem} currentUser={currentUser} darkMode={darkMode} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPolygonProjectOpen}
                onClose={closeDialogLocation}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Emplacement du projet
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <>
                            <PolylineIndex projectId={selectedItem?.id} currentUser={currentUser} />
                        </>
                    ) : (
                        <>
                            <Typography>Oups, il y a peut-être une erreur</Typography>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogLocation} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isFactureDialogOpen}
                onClose={closeFactureDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Facture projet
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <>
                            <FactureProjet projet={selectedItem} handleClose={closeFactureDialog} />
                        </>
                    ) : (
                        <>
                            <Typography>Oups, il y a peut-être une erreur</Typography>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFactureDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default ProjectList;


// import React, { useEffect, useState, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchAllProjects, deleteProject } from '../../actions/ProjetActions';
// import { Link } from 'react-router-dom';
// import OpenInFullIcon from '@mui/icons-material/OpenInFull';
// import {
//     TextField,
//     Box, Typography, Modal, Button, IconButton, useMediaQuery, Select, MenuItem,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     Tooltip,
// } from '@mui/material';
// import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
// import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
// import DeleteIcon from '@mui/icons-material/Delete';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
// import AccessDenied from "../Errors/AccessDenied";
// import { useMaterialUIController } from "../../context";
// import MDButton from '../../components/MDButton';
// import ControlPointIcon from '@mui/icons-material/ControlPoint';
// import ProjectsReview from './ProjectsReview';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import PolylineIndex from '../geometry/PolylineIndex';
// import { encodeId } from '../../actions/Crypte';
// import FactureProjet from './FactureProjet';
// import DescriptionIcon from '@mui/icons-material/Description';
// import {
//     gridPageCountSelector,
//     gridPageSizeSelector,
//     gridPageSelector,
//     useGridApiContext,
//     useGridSelector,
// } from '@mui/x-data-grid';
// import Pagination from '@mui/material/Pagination';
// import AddIcon from '@mui/icons-material/Add';

// const ProjectList = ({ currentUser }) => {
//     const [controller] = useMaterialUIController();
//     const { darkMode } = controller;
//     const dispatch = useDispatch();
//     const [currentPage, setCurrentPage] = useState(1);
//     const [projectsPerPage] = useState(10);
//     const projects = useSelector((state) => state.project.projects || []);
//     const isMobile = useMediaQuery('(max-width:600px)');
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredProjects, setFilteredProjects] = useState([]);
//     const [currentPageTotalPrice, setCurrentPageTotalPrice] = useState(0);
//     const [pageSize, setPageSize] = useState(25);
//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [isPolygonProjectOpen, setIsPolygonProjectOpen] = useState(false);

//     useEffect(() => {
//         dispatch(fetchAllProjects());
//     }, [dispatch]);


//     useEffect(() => {
//         const filteredProjects = projects?.filter((project) => {
//             const reference = project.reference_fonciere || '';
//             const numero = project.numero || '';
//             const client = project.client?.full_name || '';
//             const commercial = project.projectContact?.name || '';
//             const creation = project.date_creation || '';
//             const situation = project?.situation || '';
//             return (
//                 reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 client.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 commercial.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 creation.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 situation.toLowerCase().includes(searchTerm.toLowerCase())
//             );
//         })
//             .sort((a, b) => {
//                 if (a.date_creation === '0000-00-00' && b.date_creation !== '0000-00-00') {
//                     return 1;
//                 } else if (b.date_creation === '0000-00-00' && a.date_creation !== '0000-00-00') {
//                     return -1;
//                 } else {
//                     return new Date(b.date_creation) - new Date(a.date_creation);
//                 }
//             });

//         setFilteredProjects(filteredProjects);
//     }, [projects, searchTerm]);


//     useEffect(() => {
//         const indexOfLastProject = currentPage * projectsPerPage;
//         const indexOfFirstProject = indexOfLastProject - projectsPerPage;
//         const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

//         const currentPageTotalPrice = calculateTotalPrice(currentProjects);
//         setCurrentPageTotalPrice(currentPageTotalPrice);
//     }, [currentPage, filteredProjects, projectsPerPage]);

//     const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
//     const [currentProjectId, setCurrentProjectId] = useState(null);

//     const openDeleteModal = (projectId) => {
//         setDeleteModalOpen(true);
//         setCurrentProjectId(projectId);
//     };

//     const closeDeleteModal = () => {
//         setDeleteModalOpen(false);
//     };

//     const handleDelete = (projectId) => {
//         dispatch(deleteProject(projectId));
//     };

//     const handleConfirmDelete = () => {
//         if (currentProjectId) {
//             handleDelete(currentProjectId);
//             closeDeleteModal();
//         }
//     };

//     const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//         setCurrentPage(1);
//     };

//     const openDialogLocation = (item) => {
//         setSelectedItem(item);
//         setIsPolygonProjectOpen(true);
//     };

//     const closeDialogLocation = () => {
//         setSelectedItem(null);
//         setIsPolygonProjectOpen(false);
//     };

//     const getStatusColor = (statut) => {
//         switch (statut) {
//             case 'preparation':
//                 return '#ffa36d';
//             case 'terrain':
//                 return '#FF8C00';
//             case 'bureau':
//                 return '#FFFF00';
//             case 'controle':
//                 return '#00CED1';
//             case 'livraison':
//                 return '#00FFFF';
//             case 'cloture':
//                 return '#5F9EA0';
//             default:
//                 return '';
//         }
//     };

//     const calculateTotalPrice = (projects) => {
//         let total = 0;
//         projects?.forEach((project) => {
//             total += parseFloat(project.prix || 0);
//         });
//         return total;
//     };

//     // function CustomPagination() {
//     //     const apiRef = useGridApiContext();
//     //     const page = useGridSelector(apiRef, gridPageSelector);
//     //     const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//     //     return (
//     //         <Pagination
//     //             color="info"
//     //             count={pageCount}
//     //             page={page + 1}
//     //             onChange={(event, value) => apiRef.current.setPage(value - 1)}
//     //         />
//     //     );
//     // }


//     function CustomPagination() {
//         const apiRef = useGridApiContext();
//         const page = useGridSelector(apiRef, gridPageSelector);
//         const pageCount = useGridSelector(apiRef, gridPageCountSelector);
//         const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

//         const handleChangePage = (event, value) => {
//             apiRef.current.setPage(value - 1);
//         };

//         const handleChangePageSize = (event) => {
//             apiRef.current.setPageSize(parseInt(event.target.value, 10));
//         };

//         return (
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <Pagination
//                     color="info"
//                     count={pageCount}
//                     page={page + 1}
//                     onChange={handleChangePage}
//                 />
//                 <Select
//                     value={pageSize}
//                     onChange={handleChangePageSize}
//                     variant="outlined"
//                     style={{ marginLeft: '16px' }}
//                 >
//                     <MenuItem value={25}>25</MenuItem>
//                     <MenuItem value={50}>50</MenuItem>
//                     <MenuItem value={100}>100</MenuItem>
//                 </Select>
//             </div>
//         );
//     }

//     const modalStyle = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 500,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//     };

//     const isUserIncludePrix = currentUser &&
//         (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets"));
//     const isUserIncludeContact = currentUser &&
//         (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts des projets"));
//     const isUserPaiement = currentUser &&
//         (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les paiements des projets"));

//     const openDialog = (project) => {
//         setSelectedItem(project);
//         setIsDialogOpen(true);
//     };

//     const closeDialog = () => {
//         setSelectedItem(null);
//         setIsDialogOpen(false);
//     };

//     const openFactureDialog = (project) => {
//         setSelectedItem(project);
//         setIsFactureDialogOpen(true);
//     };

//     const closeFactureDialog = () => {
//         setSelectedItem(null);
//         setIsFactureDialogOpen(false);
//     };

//     const columns = [
//         {
//             field: 'numero',
//             headerName: 'Project',
//             ...(isMobile ? { width: 120 } : { flex: 1 }),
//             renderCell: (params) => (
//                 <Typography component={Link} to={`/projects/project/${encodeId(params.row.id)}`} className="text-decoration-none text-dark active">
//                     {params.value}
//                 </Typography>
//             ),
//         },
//         {
//             field: 'client?.full_name', headerName: 'Client',
//             ...(isMobile ? { width: 180 } : { flex: 1 }),
//             valueGetter: (params) => params.row.client?.full_name || '',
//         },
//         ...(isMobile
//             ? []
//             : [
//                 { field: 'reference_fonciere', headerName: 'Référence Foncière', flex: 1 },
//                 {
//                     field: 'nature',
//                     headerName: 'Nature',
//                     flex: 1,
//                     valueGetter: (params) => params.row.nature.map((natures) => natures.name).join(", "),
//                 },
//                 { field: 'date_creation', headerName: 'Créé à', flex: 1 },
//                 {
//                     field: 'statut',
//                     headerName: 'Status',
//                     flex: 1,
//                     renderCell: (params) => (
//                         <span className="label label-default" style={{ backgroundColor: getStatusColor(params.value) }}>
//                             {params.value}
//                         </span>
//                     ),
//                 },
//                 ...(isUserIncludePrix
//                     ? [
//                         { field: 'prix', headerName: 'Prix', flex: 1 },
//                     ]
//                     : []),
//                 ...(isUserPaiement
//                     ? [
//                         { field: 'paiement', headerName: 'Paiement', flex: 0.5 },
//                     ]
//                     : []),
//             ]
//         ),
//         {
//             field: 'actions',
//             headerName: 'Actions',
//             ...(isMobile ? { width: 180 } : { flex: 3 }),
//             renderCell: (params) => (
//                 <>

//                     <Tooltip title="Détails" arrow>
//                         <IconButton
//                             color="warning"
//                             aria-label="Preview"
//                             onClick={() => openDialog(params.row)}
//                         >
//                             <OpenInFullIcon />
//                         </IconButton>
//                     </Tooltip>
//                     {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des factures")) &&
//                         <>
//                             {(params.row?.factures?.length === 0) &&
//                                 <Tooltip title="Facturé le projet" arrow>
//                                     <IconButton
//                                         color="warning"
//                                         aria-label="Preview"
//                                         onClick={() => openFactureDialog(params.row)}
//                                     >
//                                         <DescriptionIcon />
//                                     </IconButton>
//                                 </Tooltip>
//                             }
//                         </>
//                     }
//                     {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) &&
//                         <Tooltip title="Informations" arrow>
//                             <IconButton
//                                 color="dark"
//                                 aria-label="Preview"
//                                 component={Link}
//                                 to={`/projects/project/${encodeId(params.row.id)}`}
//                             >
//                                 <VisibilityIcon />
//                             </IconButton>
//                         </Tooltip>
//                     }
//                     {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les projets")) &&
//                         <Tooltip title="Modifier" arrow>
//                             <Link to={`/projects/update/${encodeId(params.row.id)}`} className="text-decoration-none">
//                                 <IconButton color="info" aria-label="Edit" title="Edit">
//                                     <BorderColorIcon />
//                                 </IconButton>
//                             </Link>
//                         </Tooltip>
//                     }
//                     {!isMobile && (
//                         <>
//                             {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les projets")) &&
//                                 <Tooltip title="Supprimer" arrow>
//                                     <IconButton
//                                         color="error"
//                                         aria-label="Delete"
//                                         onClick={() => openDeleteModal(params.row.id)}
//                                     >
//                                         <DeleteIcon />
//                                     </IconButton>
//                                 </Tooltip>
//                             }
//                         </>
//                     )}
//                 </>
//             ),
//             cellClassName: 'actions-cell',
//         },
//     ];

//     const createFactureButton = (
//         <>
//             {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
//                 <Link to={`/projects/create`} className="text-decoration-none mt-1">
//                     <MDButton
//                         variant="contained"
//                         color='info'
//                         size='small'
//                         sx={{ marginLeft: '0.5rem' }}
//                     >
//                         <ControlPointIcon sx={{ mr: "12px", }} />
//                         Ajouter
//                     </MDButton>
//                 </Link>
//             )}
//         </>
//     );
//     const AddButton = (
//         <>
//             {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
//                 <Box
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     width="3.25rem"
//                     height="3.25rem"
//                     shadow="sm"
//                     borderRadius="50%"
//                     position="fixed"
//                     right="2rem"
//                     bottom="2rem"
//                     zIndex={99}
//                     color="dark"
//                     sx={{
//                         cursor: "pointer",
//                         background: "#0288d1",
//                         color: "#fff !important"
//                     }}
//                     component={Link}
//                     to={`/projects/create`}
//                 >
//                     <AddIcon fontSize="small" color="inherit" />
//                 </Box>
//             )}
//         </>
//     );
//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
//                 <div className="mb-3">
//                     {/* <Typography
//                         sx={{
//                             fontSize: '36px',
//                             textAlign: 'center',
//                             marginTop: '12px',
//                             fontWeight: 'bold',
//                             color: '#3e4396',
//                             textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
//                         }}
//                         variant="h2"
//                         gutterBottom
//                         mb={2}
//                         mt={4}
//                     >
//                         Liste des projets
//                     </Typography> */}
//                     <div className="search-container">
//                         <TextField
//                             fullWidth
//                             variant="standard"
//                             type="text"
//                             label="Rechercher un projet par référence, client, contact, situation, date de création ou numéro"
//                             onChange={handleSearch}
//                             value={searchTerm}
//                             name="Search Project"
//                             sx={{ gridColumn: "span 2", marginBottom: '1rem' }}
//                         />
//                     </div>
//                     <Box >
//                         <Box
//                             height="100vh"
//                         >
//                             <DataGrid
//                                 rows={filteredProjects || []}
//                                 columns={columns}
//                                 pageSize={pageSize}
//                                 onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//                                 components={{
//                                     Pagination: CustomPagination,
//                                     Toolbar: GridToolbar,
//                                     // Toolbar: (props) => (
//                                     //     <div className='d-flex' style={{ color: darkMode ? "#000" : "#000" }}>
//                                     //         {!isMobile && createFactureButton}
//                                     //         <GridToolbar {...props} />
//                                     //     </div>
//                                     // ),
//                                 }}
//                                 pagination
//                                 rowsPerPageOptions={[25, 50, 100]}
//                                 // rowsPerPageOptions={[5]}
//                                 localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
//                                 style={{ color: darkMode ? "#fff" : "#000" }}
//                                 sx={{
//                                     '& .MuiDataGrid-cell': {
//                                         color: darkMode ? "light" : "black",
//                                     },
//                                     '& .MuiDataGrid-row': {
//                                         backgroundColor: darkMode ? "rgb(36, 39, 59)" : "light",
//                                     },
//                                 }}
//                             />
//                         </Box>
//                     </Box>
//                     {AddButton}
//                 </div>
//             ) : (
//                 <>
//                     <AccessDenied />
//                 </>
//             )}
//             <Modal
//                 open={isDeleteModalOpen}
//                 onClose={closeDeleteModal}
//                 aria-labelledby="delete-modal-title"
//                 aria-describedby="delete-modal-description"
//             >
//                 <Box sx={{ ...modalStyle }}>
//                     <Typography id="delete-modal-title" variant="h5" component="h2" gutterBottom>
//                         Confirmation de suppression
//                     </Typography>
//                     <Typography id="delete-modal-description" sx={{ marginBottom: '1rem' }}>
//                         Êtes-vous sûr de vouloir supprimer ce projet ?
//                     </Typography>
//                     <MDButton onClick={handleConfirmDelete} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
//                         Confirmer la suppression
//                     </MDButton>
//                     <MDButton color='secondary' onClick={closeDeleteModal} variant="contained">
//                         Annuler
//                     </MDButton>
//                 </Box>
//             </Modal>
//             <Dialog
//                 open={isDialogOpen}
//                 onClose={closeDialog}
//                 fullWidth
//                 maxWidth="100%"
//             // maxWidth="lg"
//             >
//                 <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
//                     Détails du projet: {selectedItem?.numero}
//                 </DialogTitle>
//                 <DialogContent>
//                     <ProjectsReview project={selectedItem} currentUser={currentUser} darkMode={darkMode} />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={closeDialog} color="primary">
//                         Fermer
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             <Dialog
//                 open={isPolygonProjectOpen}
//                 onClose={closeDialogLocation}
//                 fullWidth
//                 maxWidth="md"
//             >
//                 <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
//                     Emplacement du projet
//                 </DialogTitle>
//                 <DialogContent>
//                     {selectedItem ? (
//                         <>
//                             <PolylineIndex projectId={selectedItem?.id} currentUser={currentUser} />
//                         </>
//                     ) : (
//                         <>
//                             <Typography>Oups, il y a peut-être une erreur</Typography>
//                         </>
//                     )}

//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={closeDialogLocation} color="primary">
//                         Fermer
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             <Dialog
//                 open={isFactureDialogOpen}
//                 onClose={closeFactureDialog}
//                 fullWidth
//                 maxWidth="md"
//             >
//                 <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
//                     Facture projet
//                 </DialogTitle>
//                 <DialogContent>
//                     {selectedItem ? (
//                         <>
//                             <FactureProjet projet={selectedItem} handleClose={closeFactureDialog} />
//                         </>
//                     ) : (
//                         <>
//                             <Typography>Oups, il y a peut-être une erreur</Typography>
//                         </>
//                     )}

//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={closeFactureDialog} color="primary">
//                         Fermer
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </DashboardLayout>
//     );
// };

// export default ProjectList;
